import {$, $jqHtml, Pages} from "../constants";
import ScrollThreshold from "./ScrollThreshold";

export default function initNavigation() {
    const $menuButton = $('.menu-button');
    const $header = $('header');
    const fixedClasses = "header--fixed slideInDown";
    const unfixedClasses = "header--exiting slideOutUp";
    let timeoutFn = null;

    $menuButton.click(function(){
        $header.toggleClass('header--active');
    });

    new ScrollThreshold({
        thresholdMark : 400,
        whenOverFn: () => { $header.addClass(fixedClasses) },
        whenUnderFn: (lastAction) => {
            $header.removeClass(fixedClasses);
            if(lastAction && lastAction !== ScrollThreshold.ACTIONS.UNDER){
                $header.addClass(unfixedClasses);
                clearTimeout(timeoutFn);
                timeoutFn = setTimeout(() => $header.removeClass(unfixedClasses), 250);
            }
        }
    });
}
