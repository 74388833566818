import React from 'react';
import classnames from 'classnames';
import {createPortal} from 'react-dom';

//TODO: refactor into npm library
const Modal = ({className, show = false, closeOnOuterClick = true, children, title, onClose, nodeId, closeBtn}) => {

  const classes = classnames( className, {
    modal: true,
    ['modal--show']: show
  });

  const modalRender = <div className={classes}>
    <div className="modal__overlay" onClick={closeOnOuterClick && onClose} />
    <section className="modal__body" aria-modal="true" role="dialog" tabIndex="0">
      <button onClick={onClose} className="modal__close" tabIndex="0" role="button" aria-label="Close">{ closeBtn ? closeBtn : <span className="default-close" tabIndex="0">X</span> }</button>
      { title ? <div className="modal__header">{title}</div> : null }
      <div className="modal__content">
        {children}
      </div>
    </section>
  </div>;

  if (nodeId) {
    return createPortal(
      modalRender,
      document.getElementById(nodeId)
    );
  } else {
    return modalRender;
  }
};

export default Modal;
