import React, { useState } from 'react';
import {connect} from "react-redux";
import classnames from "classnames";

const NavigationMenu = ({headerProps}) => {
    const {
        navItems,
        navTitle
    } = headerProps;
    const [ isActive, setIsActive ] = useState(false);
    const [ transition, setTransition ] = useState('');
    const cssName = 'header';
    const classes = classnames(cssName, 'animated', {
        [`${cssName}--active`]: isActive,
        [`${cssName}--fixed`]: transition === "enter",
        slideInDown: isActive,
        [`${cssName}--exiting`]: transition === "exit",
        slideOutUp: isActive
    });

    const renderedLinks = Object.values(navItems || {}).map((n,i) => {
        const { href, title, new_window } = n;
        return <li key={i}><a href={href} target={ parseInt(new_window) ? "_blank" : "_self"}>{ title }</a></li>;
    });

    return <div>
        <div className="menu-button" onClick={() => setIsActive(!isActive)}>
            <i className="ion-ios-menu"/>
        </div>
        <header className={ classes }>
            <a className="header__logo" href="/">
                <img src="/public/assets/img/logo.svg" alt="Waco Awards Logo"/>
            </a>

            <ul className="header__menu">
                { renderedLinks }
            </ul>

            { navTitle &&  <div className="header__btn">{ navTitle }</div> }
        </header>
        <a className="mobile-logo" href="/">
            <img src="/public/assets/img/emblem.svg" alt="Waco Awards Logo"/>
        </a>
    </div>
};

export {
    NavigationMenu as DisconnectedNavigation
};

export default connect(state => {
    return {
        headerProps: state.headerProps || {}
    };
})(NavigationMenu);
