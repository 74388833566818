import toastr from 'toastr';

export function notification(type, text, title, customOptions = {}){
    const timeout = customOptions.timeOut ? customOptions.timeOut : 5000;
    const options = {
        closeButton : true,
        showMethod : "slideDown",
        hideMethod : "slideUp",
        progressBar: true,
        ...customOptions,
        timeOut : timeout >= 0 ? parseInt(timeout) : 5000,
    };

    if(type === 'success'){
        toastr.success(text, title, options);
    }else if(type === "warning"){
        toastr.warning(text, title, options);
    }else if(type === "danger"){
        toastr.error(text, title, options);
    }else if(type === "info"){
        toastr.info(text, title, options);
    }
}

export const notificationTypes = {
    WARNING: 'warning',
    DANGER: 'danger',
    INFO: 'info',
    SUCCESS: 'success'
};
