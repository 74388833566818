import {$, localVotesKey} from "../constants";
import {notification, notificationTypes} from "../utils/notifications";
import getDateString from "../utils/getDateString";

class Voting {
    constructor() {
        this.canVote = true;
        this.votes = JSON.parse(window.localStorage.getItem(localVotesKey) || "{}");
        this.category_id = $('#voting_poll_category_id').val();
        this.round_id = $('#voting_poll_round_id').val();
        this.vote_key = `${this.round_id}_${this.category_id}`;
        this.vote_entry = this.votes.hasOwnProperty(this.vote_key) ? this.votes[this.vote_key] : null;
        this.form_uri = `/vote/round-${this.round_id}/${this.category_id}`;
        this.$notificationModal = $('#voteSuccessModal');
        this.$form = $('#voting-poll-form');
        this.$poll = $('.voting-category__poll');
        this.$nominee_btns = $('.vote-for-nominee-btn');
    }

    validatePoll(){
        if(this.vote_entry && this.vote_entry.hasOwnProperty('timestamp')){
            const vote_timestamp = this.vote_entry.timestamp;
            if(vote_timestamp === getDateString()){
                this.disablePoll();
                this.$poll.find('input').val(this.vote_entry.vote);
                return false;
            }
        }
        return true;
    }

    disablePoll(){
        this.$poll.addClass('voting-category__poll--disabled');
        this.$poll.find('input').attr('disabled', true);
        $('.nominee-cards').addClass('nominee-cards--disabled');
        $(`#nominee-card-${this.vote_entry.vote}, #nominee-info-${this.vote_entry.vote}`).addClass('nominee-current-active-vote');
    }

    notifyOfError(message){
        this.disablePoll();
        notification(notificationTypes.DANGER, message, "Error", {timeOut: 15000, preventDuplicates: true});
    }

    onVoteFormSubmission() {
        const that = this;
        this.$form.submit(function(e){
            e.preventDefault();
            if(that.validatePoll()){
                const formData = $(this).serializeArray();
                that._postVoteFormData(formData);
            }else{
                that.notifyOfError('You have already voted for this category today. You must wait until tomorrow to vote again');
            }
        })
    }

    onNomineeSubmission() {
        const that = this;
        this.$nominee_btns.click(function(){
            if(that.canVote) {
                const nominee_id = $(this).attr('data-nominee-id');
                that._postVoteFormData([{
                    name: 'vote',
                    value: nominee_id
                }], nominee_id)
            }else {
                that.notifyOfError('You have already voted for this category today.');
            }
        })
    }

    showSelectedNominee() {
        const nominee_exists = $('#voting_poll_nominee_exists').val();
        if(nominee_exists && nominee_exists > 0){
            $(`#nominee-info-${nominee_exists}`).modal();
        }

    }

    async _postVoteFormData(formData, nominee_id = 0){
        const that = this;
        const save_entry = {
            vote: (formData.find(f => f.name === "vote") || {}).value,
            timestamp: getDateString()
        };
        try {
            const response = await fetch(this.form_uri, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-Requested-With": "XMLHttpRequest"
                },
                body: JSON.stringify(save_entry)
            });
            const responseData = await response.json();
            console.log(responseData);
            if (responseData.status === 'success') {
                that.canVote = false;
                that.vote_entry = save_entry;
                that.disablePoll();
                $(`#nominee-info-${nominee_id}`).modal('hide');
                that.$notificationModal.modal();
                window.localStorage.setItem(localVotesKey, JSON.stringify({
                    ...that.votes,
                    [that.vote_key]: save_entry
                }));
                that.votes = JSON.parse(window.localStorage.getItem(localVotesKey) || "{}");
            } else {
                that.notifyOfError('Unable to process your vote. Your vote may be invalid due to duplicate entries or this category is not available to vote for.');
            }
        } catch (e) {
            console.log("An error occurred while saving vote", e);
        }
    }

    render() {
        console.log('rendering voting page');
        this.canVote = this.validatePoll();
        if(this.round_id === "1") {
            this.onVoteFormSubmission();
        }else if(this.round_id === "2") {
            this.showSelectedNominee();
            this.onNomineeSubmission();
        }
    }
}

export default function renderVotingPage() {
    (new Voting()).render();
}
