import {$} from "../constants";

class ComingSoon {
    constructor() {
    }

    render() {
        console.log('rendering coming soon page');
        $('header, .subscribe, .copyrights, .footer').remove();
    }
}

export default function renderComingSoonPage() {
    (new ComingSoon()).render();
}
