import React, {useState} from 'react';
import {useIntl} from "react-intl";

const VotingInputForm = ({category, onVote}) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({id: 'vote_input_placeholder'});
    const [ value, setValue ] = useState('');

    const onValueChange = e => {
        setValue(e.target.value);
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const voteData = {
            vote: value
        };
        onVote( category.id, 1, voteData);
    }

    return <div className="voting-category__poll" id="voting-round-1">
        <form onSubmit={onFormSubmit}>
            <input type="text" name="vote" value={value} onChange={onValueChange} placeholder={placeholder}/>
            <button type="submit" className="btn"><i className="fa fa-arrow-right"/></button>
        </form>
    </div>
};

export default VotingInputForm;

