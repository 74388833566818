import {localVotesKey} from "../constants";
import getDateString from "./getDateString";

const getVoteKey = (categoryId, roundId) => {
    return `${roundId}_${categoryId}`;
}

export const saveLocalVote = ({ categoryId, roundId, voteData }) => {
    const key = getVoteKey(categoryId, roundId);
    const value = {
        vote: voteData.vote,
        timestamp: getDateString()
    };
    window.localStorage.setItem(localVotesKey, JSON.stringify({
        ...(getLocalVotes()),
        [key]: value
    }));
}

export const getLocalVote = ({ categoryId, roundId }) => {
    const key = getVoteKey(categoryId, roundId);
    const votes = getLocalVotes();
    return votes.hasOwnProperty(key) ? votes[key] : null
}

export const hasLocalVote = ({ categoryId, roundId }) => {
    return !!getLocalVote({categoryId, roundId})
}

export const getLocalVotes = () => {
    return JSON.parse(window.localStorage.getItem(localVotesKey) || "{}");
}
