import React from 'react';
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";

const PageHeader = ({title, subtitle, content}) => {

    return <div className="section page-header">
        <div className="container">
            <div className="row">
                <div className="col-md-8 text-center margin-auto">
                    <div className="page-header__title">
                        { subtitle ? <small>{subtitle}</small> : null }
                        <h3>{ title }</h3>
                        { content ? <MarkupRender content={content} /> : null }
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default PageHeader;
