import {$} from "../constants";
//TODO: extract to NPM
class ScrollThreshold {

    constructor(opts){
        const noOp = () => {};
        const {
            thresholdMark = 0,
            whenUnderFn = noOp,
            whenOverFn = noOp
        } = opts;
        this.whenUnderFn = whenUnderFn || noOp;
        this.whenOverFn = whenOverFn || noOp;
        this.whenEqualFn = opts.whenEqualFn || whenUnderFn;
        this.thresholdMark = thresholdMark;
        this.lastAction = "";
        this._init();
    }

    _init(){
        const that = this;
        this._runFunctions();
        window.addEventListener('scroll', function() {
            that._runFunctions();
        });

    };

    _runFunctions(){
        const position = window.scrollY;

        if(position === this.thresholdMark && this.lastAction !== ScrollThreshold.ACTIONS.EQUAL){
            this.whenEqualFn(this.lastAction);
            this.lastAction = ScrollThreshold.ACTIONS.EQUAL;
        }else if(position > this.thresholdMark && this.lastAction !== ScrollThreshold.ACTIONS.OVER){
            this.whenOverFn(this.lastAction);
            this.lastAction = ScrollThreshold.ACTIONS.OVER;
        }else if(position < this.thresholdMark && this.lastAction !== ScrollThreshold.ACTIONS.UNDER){
            this.whenUnderFn(this.lastAction);
            this.lastAction = ScrollThreshold.ACTIONS.UNDER;
        }
    };
}

ScrollThreshold.ACTIONS = {
    OVER: 'over',
    UNDER: 'under',
    EQUAL: 'equal'
};

export {
    ScrollThreshold as default
}
