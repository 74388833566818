import {VotingActions} from "../actions/voting-actions";

const votingReducer = (state, action) => {
  switch (action.type) {
    case VotingActions.VOTE_REQUEST_COMPLETED: {
      console.log('Update the local votes state', action);
      return state;
    }

    default:
      return state;
  }
};

export default votingReducer;
