import React from 'react';
import {connect} from "react-redux";
import PageHeader from "../PageHeader";

const VotingPage = ({page, posts, round, content}) => {

    const categories = posts.map(category => <div className="category-card">
        <a href={`/voting/${category.slug}`}>
            <div className="category-card__info">
                {round === "WINNERS_ROUND_ID" ?
                    <div className="category-card__img" style={`background-image: url('/public/uploads/${category.image}')`}/>
                    : null
                }
                <h2 className="category-card__title">{ category.title }</h2>
                <small className="category-card__subtitle">{ category.field_1 }</small>
            </div>
        </a>
    </div>
    );

    return <React.Fragment>
        <PageHeader title={page.title} subtitle={page.content} content={content} />
        <div className="section bg-light">
            <div className="container d-flex flex-row flex-wrap">
                { categories }
            </div>
        </div>
    </React.Fragment>
};

export default connect(state => {
    return {
        page: state.page || {},
        posts: state.posts || {},
        round: state.round || {},
        content: state.content || {},
    };
})(VotingPage);
