import Swiper from 'swiper/js/swiper.min';

class Nominees {
    constructor() {
        this.slideshow = null;
        this.elementId = '#the-show';
    }
    
    initPresentation(){
        this.slideshow = new Swiper(this.elementId, {
            speed: 400,
            direction: 'horizontal',
            autoplay: {
                delay: 5000,
                stopOnLastSlide: true
            },
            loop: false,
            effect: 'slide',
            initialSlide: 0
        });
    }

    render() {
        console.log('rendering nominees presentation');
        this.initPresentation();
    }
}

export default function renderNomineesPage() {
    (new Nominees()).render();
}
