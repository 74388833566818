import React from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import VotingNominees from "../VotingNominees";
import VotingInputForm from "../VotingInputForm";
import {postVote} from "../../actions/voting-actions";

const VotingCategoryPage = ({page, category, description, onVote, round, vote_page_content}) => {

    return <React.Fragment>
        <div className="section voting-page-header">
            <div className="container">
                <div className={`row voting-category voting-category--${round}`}>
                    <div className="col-md-8 text-center margin-auto">
                        <div className="voting-category__title">
                            <div className="voting-category__tag">
                                <FormattedMessage id={`round_tag_${round}`} />
                            </div>
                            <span>{ category.title }</span>
                            { category.subtitle ? <div className="voting-category__subtitle">{ category.subtitle }</div> : null }
                        </div>
                        <div className="voting-category__details">{ description }</div>
                        { round === 1 ? <VotingInputForm category={category} onVote={onVote} /> : null }
                    </div>
                </div>
            </div>
        </div>

        { round === 2 ?
            <div className="section bg-light nominee-cards">
                <div className="container">
                    {vote_page_content.field_4 ?
                        <div className="text-center nominee-cards__instructions">
                            {vote_page_content.field_4}
                        </div>
                        : null
                    }
                    <div className="row justify-content-center">
                        <VotingNominees category={category} onVote={onVote} />
                    </div>
                </div>
            </div>
            : null
        }
    </React.Fragment>
};

export default connect(state => {
    return {
        page: state.page || {},
        category: state.category || {},
        round: state.round || {},
        vote_page_content: state.vote_page_content || {},
        description: state.description || {},
    };
}, dispatch => {
    return {
        onVote: (categoryId, roundId, voteData) => {
            dispatch(postVote({categoryId, roundId, voteData}));
        }
    }
})(VotingCategoryPage);
