import "bootstrap/dist/js/bootstrap";
import "bootstrap-select/dist/js/bootstrap-select";
import {$, $jqHtml, Pages} from "./constants";
import renderComingSoonPage from "./pages/coming-soon";
import {notification} from "./utils/notifications";
import initNavigation from "./utils/initNavigation";
import searchBox from "./utils/searchBox";
import renderVotingPage from "./pages/voting";
import renderNomineesPage from "./pages/nominees";
import {extractElementData, fetchApiData} from "@nutastic/nutastic-react-app/src/utils";
import {initializeNutasticApp} from "@nutastic/nutastic-react-app";
import App from "./components/App";
import AppLoading from "./components/AppLoading";
import messages from './i18n/messages.mjs';
import votingReducer from "./reducers/voting-reducer";

$(() => {
    console.log('running page scripts');

    if($jqHtml.hasClass(Pages.COMING_SOON)){
        renderComingSoonPage();
    }
    if($jqHtml.hasClass(Pages.VOTING)){
        renderVotingPage();
    }
    if($jqHtml.hasClass(Pages.NOMINEES)){
        renderNomineesPage();
    }

    if($('.search-box').length) {
        console.log('initializing search box selectors');
        $('.search-box select').selectpicker();
    }

    $('.toastNotification').each(function(){
        const $object = $(this);
        const title = $object.attr('data-toast-title');
        const text = $object.attr('data-toast-text');
        const type = $object.attr('data-toast-type');
        const timeout = $object.attr('data-toast-timeout');
        const options = {
            timeOut : timeout && timeout >= 0 ? parseInt(timeout) : 5000
        };

        notification(type, text, title, options);
    });

    initNavigation();
    searchBox();

    if(document.getElementById('root')) {
        initializeNutasticApp({
            AppComponent: App,
            LoadingComponent: AppLoading,
            initialState: extractElementData(),
            decorateStateFn: fetchApiData,
            messages,
            reducers: [votingReducer]
        })
    }
});
