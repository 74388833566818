import React, {Fragment} from 'react';
import {connect} from "react-redux";
import NavigationMenu from "./NavigationMenu";
import {Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage";
import VotingPage from "./pages/VotingPage";
import VotingCategoryPage from "./pages/VotingCategoryPage";
import CustomPage from "./pages/CustomPage";

const App = () => {
    const renderPageRoute = () => {
        return <Switch>
            <Route path='/' exact={true} component={HomePage}/>
            <Route path='/home' render={props => <HomePage {...props} />}/>
            <Route path='/react-voting' exact={true} render={props => <VotingPage {...props} />}/>
            <Route path='/react-voting/:slug' render={props => <VotingCategoryPage {...props} />}/>
            <Route path='/react-page/:slug' render={props => <CustomPage {...props} />}/>
        </Switch>
    };

    return <Fragment>
        <NavigationMenu />
        { renderPageRoute() }
    </Fragment>
};

export default connect(state => {
    return {
        page: state.page || {}
    }
})(App);
