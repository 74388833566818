import React, {Fragment} from 'react';
import {ReactSVG} from "react-svg";
import {DisconnectedNavigation} from "./NavigationMenu";

const AppLoading = () => {
    return <Fragment>
        <DisconnectedNavigation headerProps={{}} />
        <div className="appIsLoading">
            <ReactSVG src="/public/assets/img/loading.svg" className="appIsLoading__icon" />
        </div>
    </Fragment>
};

export default AppLoading;
