import {$} from "../constants";

export default function searchBox() {
    const $form = $('#search-box-form');
    $form.submit(function(e){
        e.preventDefault();
        const value = $form.find('select').val();
        console.log('Redirecting from search box change:', value);
        if(value){
            window.location.href = `/voting/${value}`;
        }
    })
}
