import React from 'react';

export const notify = msg => {
    alert(msg);
}

const Notification = ({title, content}) => {
    return <div className="notification">
        {title}
        {content}
    </div>
};

export default Notification;
