import React from 'react';
import {connect} from "react-redux";
import {FormattedHTMLMessage} from "react-intl";

const HomePage = ({page}) => {
    const {
        content: text,
        field_1: subtext,
        field_2: link
    } = page;

    return <div className="page-header">
        <div className="container">
            <div className="flyer">
                <div className="flyer__text">
                    <FormattedHTMLMessage id='homepage.flyerText' />
                </div>
                <a href={link} className="flyer__block">
                    <button>{ text }</button>
                    <span>{ subtext }</span>
                    <span className="icon">
                        <i className="fa fa-long-arrow-right"/>
                    </span>
                </a>
            </div>
        </div>
    </div>
};

export default connect(state => {
    return {
        page: state.page || {}
    };
})(HomePage);
