import React, {Fragment} from 'react';
import {connect} from "react-redux";
import PageHeader from "../PageHeader";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";

const CustomPage = ({page}) => {
    const { title, content, field_1, field_2, field_3 } = page;

    const renderTextArea = (field, useMargin=false) => {
        return field ? <div className={`section page-content ${!useMargin ? 'pt-0' : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-widget text-center">
                            <MarkupRender content={field} />
                        </div>
                    </div>
                </div>
            </div>
        </div> : null
    }

    const pageContent = field_2 ? <div className="section lb page-content">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-widget text-center">
                        <MarkupRender content={field_2} />
                    </div>
                </div>
            </div>
        </div>
    </div>: null;

    return <Fragment>
        <PageHeader title={title} subtitle={content} />
        { renderTextArea(field_1) }
        { pageContent }
        { renderTextArea(field_3, true) }
    </Fragment>
};

export default connect(state => {
    return {
        page: state.page || {}
    };
})(CustomPage);
