import getDateString from "../utils/getDateString";
import {localVotesKey} from "../constants";
import {hasLocalVote, saveLocalVote} from "../utils/localVoteDatabase";
import {notify} from "../components/Notification";

const VotingActions = {
  VOTE_REQUEST_STARTED: 'VOTE_REQUEST_STARTED',
  VOTE_REQUEST_COMPLETED: 'VOTE_REQUEST_COMPLETED',
  VOTE_REQUEST_FAILED: 'VOTE_REQUEST_FAILED'
};

const postVote = ({categoryId, roundId, voteData}) => {
  return async dispatch => {
    dispatch( votingRequestStarted() );
    const postUri = `/vote/round-${roundId}/${categoryId}`;
    if( !hasLocalVote({ categoryId, roundId }) ) {
      try {
        dispatch(votingRequestStarted());
        const response = await fetch(postUri, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "X-Requested-With": "XMLHttpRequest"
          },
          body: JSON.stringify(voteData)
        });

        const responseData = await response.json();
        console.log(responseData);
        saveLocalVote({categoryId, roundId, voteData});
        notify('Thanks for voting');
        dispatch(votingRequestCompleted());
      } catch (ex) {
        dispatch(voteRequestFailed(ex));
        notify('An error occurred while casting vote');
      }
    } else {
      notify('Oops, you already voted');
    }
  }
};

const votingRequestStarted = () => {
  return {
    type: VotingActions.VOTE_REQUEST_STARTED
  }
};

const votingRequestCompleted = () => {
  return {
    type: VotingActions.VOTE_REQUEST_COMPLETED
  }
};

const voteRequestFailed = (error) => {
  return {
    type: VotingActions.VOTE_REQUEST_FAILED,
    error
  }
};

export {
  VotingActions,
  postVote
};
