import * as jQuery from 'jquery'

export const $ = jQuery;
export const $jqBody = jQuery('body');
export const $jqHtml = jQuery('html');
export const localVotesKey = 'waco-awards-2019-votes';
export const Pages = {
    HOME : 'home-page',
    COMING_SOON: 'coming-soon-mode',
    VOTING: 'voting',
    NOMINEES: 'nominees',
};
