import React, {useState} from 'react';
import Modal from "./Modal";
import MarkupRender from "@nutastic/nutastic-react-app/src/components/MarkupRender";

const VotingNominees = ({category, onVote}) => {
    const [ activeNominee, setActiveNominee ] = useState(false);
    const { nominees } = category;

    const onVoteClick = nominee => {
        console.log(`Nigga you voted for ${nominee.name}. WOW!`);
        const voteData = {
            vote: nominee.banner_id
        };
        onVote(category.id, 2, voteData);
    }

    //TODO: create method for grabbing assets and uploads
    const getNomineeImage = nominee => nominee.image ? `/public/uploads/${nominee.image}` : '/public/assets/img/nominee.jpg';

    const nomineeCards = nominees.map( nominee => {
        return <div className="nominee" key={nominee.banner_id}>
            <div className="nominee__image" style={{ backgroundImage: `url('${getNomineeImage(nominee)}')` }} onClick={() => setActiveNominee(nominee)}/>
            <div className="nominee__description">
                <div className="pull-left text-left">
                    <h4>{ nominee.name }</h4>
                    <p>{ nominee.field_1 }</p>
                </div>
                <div className="nominee__vote-button pull-right text-center vote-for-nominee-btn" onClick={() => onVoteClick(nominee)}>
                    <i className="fa fa-heart-o vote-icon"/>
                </div>
            </div>
        </div>
    });

    const renderNomineeModal = () => {
        const links = JSON.parse(activeNominee.field_5 || "[]");
        const nomineeLinks = (links || []).map(link => <a href={link.url} target="_blank">{link.text}</a> );

        return !activeNominee ? null :
            <Modal show={true} className="modal--nominee" onClose={() => setActiveNominee(false)}>
                <div className="modal-body">
                    <div className="nominee-image" style={{ backgroundImage: `url('${getNomineeImage(activeNominee)}')` }}/>
                    <img className="nominee-image-full" alt={ activeNominee.name } src={ getNomineeImage(activeNominee) } />
                    <div className="nominee-info">
                        <div className="nominee-info__info">
                            <h6>{ activeNominee.name }</h6>
                            <MarkupRender content={activeNominee.text} />
                        </div>

                        { nomineeLinks.length ?
                            <div className="nominee-info__links">
                                <span className="nominee-info__title">Links:</span>
                                { nomineeLinks }
                            </div>
                            :
                            null
                        }

                        <div className="nominee-info__vote">
                            <span className="nominee-info__title">Vote <span>{ activeNominee.name }</span> for { category.title }</span>
                            <i className="fa fa-heart-o vote-icon vote-for-nominee-btn" onClick={() => onVoteClick(activeNominee)} />
                        </div>
                    </div>
                </div>
            </Modal>
    };


    return <React.Fragment>
        { nomineeCards }
        { renderNomineeModal() }
    </React.Fragment>
};

export default VotingNominees;
